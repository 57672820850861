import React from 'react';
import './Banner.css';
import logo from '../../assets/img/logo.png';
import { Container, Row, Col } from 'reactstrap';

function Banner() {
    return (
        <header className="banner-gracias">
            <Container fluid={true} className="padding0">
                <Row>
                    <Col md="1" className="padding0 hidden-sm"></Col>
                    <Col md="7" sm="12" className="padding0 paddingb40">
                        <Row>
                            <Col md="5"></Col>
                            <Col md="7" sm="12">
                                <img src={logo} className="logo-gracias" alt="logo" />
                            </Col>
                        </Row>
                    </Col>
                    <Col md="4" className="padding0"></Col>
                    <Col md="4" className="padding0  hidden-sm"></Col>
                    <Col md="4" sm="12" className=" text-center padding0 paddingb40">
                        <span className="hyper-text">GRACIAS</span>
                        <span className="mega-text">POR TU REGISTRO</span>
                        <a className="btn-volver" href="/">VOLVER</a>
                        <br />
                        <span className="bold fore-white">¿Conectamos?</span>
                        <br />
                        <Row>
                            <Col md="12" className="redesfooter">
                            <a href="https://www.facebook.com/constructoravalderrama/" className="facebook" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a  href="https://www.youtube.com/watch?v=4hWTuqkbkes" className="youtube" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-youtube-play"></i>
                        </a>
                        <a  href="https://www.instagram.com/constructora_valderrama/?hl=es-la" className="instagram" target="_blank" rel="noopener noreferrer"> 
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a  href="https://twitter.com/constvalderrama?lang=es" target="_blank" className="twitter" rel="noopener noreferrer">
                            <i className="fa fa-twitter"></i>
                        </a>
                            </Col>

                        </Row>
                    </Col>

                    <Col md="4" className="padding0 hidden-sm"></Col>
                </Row>
            </Container>

        </header>
    );
}

export default Banner;
