import React from 'react';
import './Footer.css';
import telefono from '../../assets/img/telefono-footer.png';
import correo from '../../assets/img/correo-footer.png';
import direccion from '../../assets/img/direccion-footer.png';
import sitio from '../../assets/img/url-footer.png';
import logo from '../../assets/img/logo-footer.png';
import valderrama from '../../assets/img/logo-valderrama.png';
import { Container, Row, Col } from 'reactstrap';
import Whatsapp from '../whatsapp/Whatsapp';

const Footer = (props) => {
    return (
    <Container fluid={true} className="footer padding0">
        <Whatsapp />
        <Row className="border-footer-gold"></Row>
        <Row>
            <Col md="4" sm="6"  className="padding0 back-yellow">
                <img src={logo} alt="Logo Privilege" className="img-logo-footer" />
            </Col>
            <Col md="4"  sm="6" className="padding0">
                <Row>
                    <Col md="12" className="redesfooter">
                        <a href="https://www.facebook.com/constructoravalderrama/" className="facebook" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a  href="https://www.youtube.com/watch?v=4hWTuqkbkes" className="youtube" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-youtube-play"></i>
                        </a>
                        <a  href="https://www.instagram.com/constructora_valderrama/?hl=es-la" className="instagram" target="_blank" rel="noopener noreferrer"> 
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a  href="https://twitter.com/constvalderrama?lang=es" target="_blank" className="twitter" rel="noopener noreferrer">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </Col>
                </Row>                      
                <Row>
                    <Col md="12">
                        <br/>
                    <a href="https://constructoravalderrama.com" target="_blank" rel="noopener noreferrer"><img src={valderrama} alt="Logo Valderrama y Logo Peninsula" className="img-logo-footer" /></a>
                    </Col>
                </Row>
            </Col>
            <Col md="4" sm="12" className="padding0">
                <div className="square-footer">
                    <div>
                        <img src={telefono} alt="Teléfono" width="20" /> <a href="tel:+573013305001">+57 301 330 5001 </a>
                    </div>
                    <div>
                        <img src={correo} alt="Correo" width="20" /> <a href="mailto:Contacto@constructoravalderrama.com.co">Contacto@constructoravalderrama.com.co</a>
                    </div>
                    <div>
                        <img src={direccion} alt="Dirección" width="20" /> <a href="https://www.google.com/maps/place/Cra.+41+%2342-39,+Bucaramanga,+Santander/@7.1219743,-73.1080322,17z/data=!3m1!4b1!4m5!3m4!1s0x8e68155f49b01189:0x3cf0d52a6978a5fc!8m2!3d7.121969!4d-73.1058435" target="_blank" rel="noopener noreferrer">Carrera 41 # 42 - 39, Bucaramanga</a>
                    </div>
                    <div>
                        <img src={sitio} alt="Sitio Web" width="20" /> <a href="https://constructoravalderrama.com" target="_blank" rel="noopener noreferrer">www.constructoravalderrama.com</a>
                    </div>                   
                </div>
            </Col>
        </Row>
    </Container>
  );
}

export default Footer;
