import React, { useState } from 'react';
import './Basic.css';
import elegancia from '../../assets/img/elegancia.png';
import descripcion from '../../assets/img/descripcion.png';

import { Container, Row, Col } from 'reactstrap';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

function importAll(r) {
  return r.keys().map(r);
}

const items = importAll(require.context('../../assets/img/slider-dos/', false, /\.(png|jpe?g|svg)$/));

function Basic() {

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }




  return (
    <Container fluid className="relative">

      <Container className="section-estandar">
        <Row>
          <Col md="7">
              <Carousel className="top-slider" activeIndex={activeIndex}
      next={next}
      previous={previous}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                { items.map((image, indx) => {
                                return (
                                  <CarouselItem
                                  onExiting={() => setAnimating(true)}
                                  onExited={() => setAnimating(false)}
                                  key={indx}
                                >
                                  <img  className="d-block w-100" src={image} alt="Puerta de Oro" />
                                </CarouselItem>
            
                                )
                            })}
                </Carousel>
          </Col>
          <Col md="5" className="text-justify fore-gray mtop40 center-vertical">
            <img src={elegancia} className="img-elegancia" alt="Elegancia" />
            <p>Bienvenido a <b>PUERTA DE ORO</b> CONDOMINIO. El primer complejo residencial y comercial de Girón; diseñado para brindarte a tí y a tu familia un nuevo estilo de vida.</p>

            <p>En <b>PUERTA DE ORO</b> CONDOMINIO estarás rodeado de múltiples amenidades y zonas comunes para toda la familia. Estarás a menos de 5 minutos del Casco Antiguo de Girón y a tan solo 7 minutos de Floridablanca y Bucaramanga. Además, disfutarás de una gran variedad de ofertas comerciales y una elegante zona empresarial.</p>

            <Row>
            <img src={descripcion} className="img-elegancia" alt="elegancia" />
            </Row>
          </Col>
        </Row>

      </Container>

    </Container>
  );
}

export default Basic;
