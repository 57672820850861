import React from 'react';
import './Top.css';
import telefono from '../../assets/img/telefono.png';
import correo from '../../assets/img/correo.png';
import flag from '../../assets/img/flag.png';
import { Container, Row, Col } from 'reactstrap';

const Top = (props) => {
    return (
    <Container fluid={true} className="back-black">
        <Row>
            <Col md="10" sm="12" className="padding0">
                <Row className="texto-top">
                    <Col md="11" sm="12" className="text-right padding0"><span><a href="https://www.google.com/maps/place/7%C2%B004'58.9%22N+73%C2%B010'07.4%22W/@7.0830207,-73.1698053,467m/data=!3m2!1e3!4b1!4m13!1m6!3m5!1s0x8e683e628ef082cb:0xb7e9598d50767c78!2sCodiesel!8m2!3d7.0821088!4d-73.1680812!3m5!1s0x0:0x0!7e2!8m2!3d7.0830183!4d-73.1687109" target="_blank" rel="noopener noreferrer">Barrio el Palenque Auto. P Girón Frente a Codiesel&nbsp;</a> </span> <span> (<img src={flag} width="20" alt="Colombia" />+57) </span> <span><img src={telefono} alt="Teléfono" width="20" /> <a href="tel:+573013305001">301 330 5001</a> &nbsp;</span><span><img src={correo} alt="Correo" width="20" /> <a href="mailto:Contacto@constructoravalderrama.com.co">contacto@constructoravalderrama.com.co</a></span></Col>
                    <Col md="1" className="hidden-sm hidden-xs"></Col>
                </Row>
            </Col>
            <Col md="2" sm="12" className="padding0">
                <Row>
                    <Col md="12" className="text-left redes padding0">
                    <a href="https://www.facebook.com/constructoravalderrama/" className="facebook" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a  href="https://www.youtube.com/watch?v=4hWTuqkbkes" className="youtube" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-youtube-play"></i>
                        </a>
                        <a  href="https://www.instagram.com/constructora_valderrama/?hl=es-la" className="instagram" target="_blank" rel="noopener noreferrer"> 
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a  href="https://twitter.com/constvalderrama?lang=es" target="_blank" className="twitter" rel="noopener noreferrer">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </Col>
                </Row>        
            </Col>
        </Row>
    </Container>
  );
}

export default Top;
