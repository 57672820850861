import React from 'react';
import './Gracias.css';
import Planosg from './componentes/planosg/Planosg';
import Footer from './componentes/footer/Footer';
import Banner from './componentes/banner/Banner';

function Gracias() {
  return (
    <div className="Gracias">
      <Banner />
      <Planosg />
      <Footer />
    </div>
  );
}

export default Gracias;
