import React, { useState } from 'react';
import './Planosg.css';
import { Container, Row, Col } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import valderrama from '../../assets/img/logo-valderrama-color.png';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/img/plano/', false, /\.(png|jpe?g|svg)$/));

const items = [];
let item = {};
for (let i = 1; i < images.length; i++) {
    item = {
        src: images[i],
    }
    items.push(item);
}


const Planosg = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
  
   
    return (
        <Container className="padding0 planosg">
            <Row>
                <Col md="3">

                </Col>
                <Col md="6">
                    <img src={valderrama} class="img-valderrama" alt="Valderrama Logo y Peninsula Logo" />
                    <br/>&nbsp;
                    <br/>
                </Col>
                <Col md="3">
                    
                </Col>

            </Row>
            <Row>
                <Col md="12" className="paddingt40">
                    <Row>
                        <Col className="text-center">
                            <span className="torre">TORRE 1 &amp; 2:</span> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        TIPO A
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col className="text-center">
                            <span className="torre">TORRE 3 &amp; 4: </span>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        TIPO A
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggle('3'); }}
                                    >
                                        TIPO B
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <img src={images[0]} alt="Tipo A" className="img-plano hidden-sm" />
                                <img src={images[1]} alt="Tipo A" className="img-plano hidden-md" />
                            </Col>
                        </Row>
                        </TabPane>
                        <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <img src={images[2]} alt="Tipo B" className="img-plano hidden-sm" />
                                <img src={images[3]} alt="Tipo B" className="img-plano hidden-md" />
                            </Col>
                        </Row>
                        </TabPane>
                        <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <img src={images[4]} alt="Tipo C" className="img-plano hidden-sm" />
                                <img src={images[5]} alt="Tipo C" className="img-plano hidden-md" />
                            </Col>
                        </Row>
                        </TabPane>
                       </TabContent>
                </Col>
            </Row>
           
           <Row className="paddingt40">
                <Col md="12" className="disclaimer">
                * Las imágenes y contenidos aquí publicados pueden no coincidir en su totalidad con la realidad de la construcción. Los materiales texturas, colores, equipamiento y mobiliario que se exhibe es solamente ilustrativo y para efecto de pre-ventas. Este material está destinado exclusivamente para la información y/o consulta de los potenciales compradores. Los cambios que el proyecto pueda tener son exclusividad de la constructora sin tener que dar previo aviso, siempre y cuando estos no afecten la calidad del producto final.
                </Col>
            </Row>
        </Container>
    );
}



export default Planosg;
