import React from 'react';
import './Home.css';
import Top from './componentes/top/Top';
import Header from './componentes/header/Header';
import Basic from './componentes/basic/Basic';
import Planos from './componentes/planos/Planos';
import Proyecto from './componentes/proyecto/Proyecto';
import Footer from './componentes/footer/Footer';
import Captcha from './componentes/captcha/Captcha';

function Home() {
  return (
    <div className="App">
      <Top />
      <Header />
      <Basic />
      <Planos />
      <Proyecto />
      <Captcha />
      <Footer />
    </div>
  );
}

export default Home;
