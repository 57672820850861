import React from 'react';
import { useHistory } from "react-router-dom";
import './Header.css';
import logo from '../../assets/img/logo.png';
import lanzamiento from '../../assets/img/lanzamiento.png';
import insignia from '../../assets/img/insignia.png';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';

function Header() {
  let history = useHistory();

  /*const showPop = () =>{
    $("#popup-camp").css("display","block");                
  }*/

  const hidepop = () =>{
    $("#popup-camp").css("display","none");
  } 


  const handleFormSubmit = event => {
    event.preventDefault();
    $('#msg').html("");
    if($('#nombre').val().length === 0){
      $('#msg').html("<span class='fore-white bold medium'>Ingresa tu nombre.</span>");
      return false;
   }
   if($('#email').val().length === 0){
    $('#msg').html("<span class='fore-white bold medium'>Ingresa tu correo para poder contactarte.</span>");
    return false;
}else{
  let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if(!regex.test($('#email').val())){
    $('#msg').html("<span class='fore-white bold medium'>Revisa tu correo para poder contactarte.</span>");
    return false;
  }
}
    if($('#celular').val().length === 0){
        $('#msg').html("<span class='fore-white bold medium'>Ingresa tu celular.</span>");
        return false;
    }
    

    if($('#mensaje').val().length === 0){
      $('#msg').html("<span class='fore-white bold medium'>Ingresa tu mensaje.</span>");
      return false;
  }
    // Test
    $('#btn-send').attr("disabled", "true");
    $('#msg').html("<span class='fore-white bold medium'>Enviando...</span>");
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LdomOYUAAAAAPFcgGufsFhhpCLOJOOCf2y5wTSw", { action: "homepage" })
        .then(token => {
          document.getElementById('token').value = token
          
          // Send the form with AJAX
          $.ajax({
            data: $('form').serialize(),
            type: 'POST',
            url: process.env.PUBLIC_URL + '/enviar.php',
            success: function (data) {
              $('#btn-send').attr("disabled", "false");
              if (data === 1) {
               // showPop();
                setTimeout(function(){
                  history.push("/gracias");
                }, 1000); 
              } else {
                if (data === 0) {
                  $('#msg').html("<span class='fore-white bold medium'>Error al enviar</span>");
                } else {
                  if (data === 3) {
                    $('#msg').html("<span class='fore-white bold medium'>No pasaste nuestro control anti-spam, revisa los datos</span>");
                  } else {
                    $('#msg').html("<span class='fore-white bold medium'>Error, revisa por favor los campos</span>");
                  }
                }

              }

            },
            error: function (xhr, status, err) {
              console.error(status, err.toString())
            }
          })
        
        
        })
      });
     
      // Reset state after sending the form

  }

  const getPrice = () => {

    $.ajax({
      data: { meta_key: 'puertadeoro_precio' },
      type: 'POST',
      url: 'https://constructoravalderrama.com/service/',
      success: function (data) {
        try {
          let datos = JSON.parse(data);
          if (datos.resp) {
            $("#precioGet").html(`$${datos.datos.meta_value}`);
          }
        } catch (error) {
          console.log(error);
        }

      },
      error: function (xhr, status, err) {
        console.error(status, err.toString())
      }
    })
  }

  getPrice();

  return (
    <header className="banner">
      <Container fluid={true} className="padding0">
        <Row>
          <Col md="1" className="padding0 hidden-sm"></Col>
          <Col md="10" sm="12" className="padding0">
            <Row>
              <Col md="2">
                <img src={logo} className="logo" alt="Fernando Valderrama Cordero" />
              </Col>
              <Col md="10">
              <p className="disclaimer fore-white">Las imágenes y contenidos aquí publicados pueden no coincidiren su totalidad con la realidad de la construcción. Los materiales texturas, colores, equipamiento y mobiliario que se exhibe es solamente ilustrativo y para efecto de pre-ventas. Este material está destinado exclusivamente para la información y/o consulta de los potencial es compradores. Los cambios que el proyecto pueda tener son exclusividad de la constructora sin tener que dar previo aviso, siempre y cuando estos no afecten la calidad del producto final.</p>
              </Col>
            </Row>
            <Row>
              <Col lg="7" md="8" sm="6" xs="12" className="padding0">
                <Row>
                    <Col lg="4" md="4" sm="1"></Col>
                    <Col lg="8" md="8" sm="12" className="border-yellow-top"></Col>
                </Row>
                <Row>
                  <img src={lanzamiento} className="lanzamiento" alt="Ricardo valderrama Cordero" />
                  <div className="square-gold">
                    <span className="text-top">APARTAMENTOS APARTIR DE:</span>
                    <span className="big-number" id="precioGet">$</span> <span className="text-bottom">MILLONES</span>
                  </div>
                </Row>
                <Row>
                    <Col lg="4" md="4" sm="1"></Col>
                    <Col lg="8" md="8" sm="12" className="border-yellow-bottom"></Col>
                </Row>
              </Col>
              <Col lg="5" md="4" sm="6" xs="12" className="padding0 flex-end">
              <img src={insignia} className="insignia" alt="Primer complejo comercial y residencial" />
                <div className="float-form">
               
                  <span className="fore-white titulo-form">TOMA UNA ASESORÍA</span>
                  <form>
                    <input type="hidden" id="token" name="token" value="" />
                    <input type="hidden" name="action" value="homepage" />
                    <input type="text" placeholder="Nombre*" id="nombre"  name="nombre" required/>
                    <input type="email" placeholder="Email*" id="email" name="email" required/>
                    <input type="text" placeholder="Celular*" id="celular" name="celular" required/>
                    <textarea placeholder="Mensaje*" name="mensaje" id="mensaje" required></textarea>
                    <div id="msg"></div>
                    <button onClick={handleFormSubmit} >ENVIAR</button>
                  </form>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="1" className="padding0"></Col>
        </Row>
      </Container>
      <div id="popup-camp" onClick={hidepop} ><img src="https://constructoravalderrama.com/wp-content/uploads/2020/10/BONO-ANIMADO-PUERTA-DE-ORO.gif" alt="popup"/><div id="close-btn" onClick={hidepop} >Cerrar</div></div>
    </header>
  );
}

export default Header;
