import React from 'react';
import './App.css';
import Home from './Home';
import Gracias from './Gracias';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; 

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/gracias">
            <Gracias/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div> 
  );
}

export default App;
