import React from 'react';
import './Proyecto.css';
import plano from '../../assets/img/plano.jpg'
import asterisco from '../../assets/img/asterisco.png'
import etapa from '../../assets/img/etapas.png'
import { Container, Row, Col } from 'reactstrap';

function Proyecto() {
  return (
      <Container fluid={true} className="proyecto padding0">
            <Container>
                <Row>
                  <Col md={5} className="border-right-yellow">
                        <img src={etapa} className="img-elegancia" alt="etapa" />
                  </Col>
                  <Col md={6} className="border-top-yellow">

                  </Col>      
                </Row>
            </Container>  
            <Container fluid={true} className="proyecto-info back-black">
                <Container>
                    <Row>
                    <Col md={5} sm={12} className="">
                        <Row>
                            <Col md={6}>
                                <h4>ETAPA<span class="number">1</span></h4>
                                <ul class="etapa-list">
                                    <li>TORRE UNO</li>
                                    <li><b>LOBBY</b> TIPO HOTEL DOBLE ALTURA</li>
                                    <li><b>CANCHA</b> FUNCIONAL</li>
                                    <li><b>MINI CANCHA</b> SINTÉTICA FÚTBOL </li>
                                    <li>2 <b>SALONES SOCIALES</b></li>
                                    <li>2 <b>CINEMAS</b></li>
                                    <li><b>TERRAZA BAR</b> BBQ</li>
                                    <li>ZONA <b>JUEGOS INFANTILES</b></li>
                                    <li>ZONA <b>JUEGOS DE MESA</b></li>
                                    <li>ZONA DE <b>JUEGOS JUNIOR</b></li>
                                    <li>1ER TRAMO DE <b>CICLORUTA</b></li>
                                </ul>
                                <h4>ETAPA<span class="number">2</span></h4>
                                <ul class="etapa-list">
                                    <li>TORRE DOS</li>
                                    <li><b>PISCINA</b> INFANTIL</li>
                                    <li>ZONA DE <b>PLAYA</b></li>
                                    <li>2DO TRAMO DE <b>CICLORUTA</b></li>
                                </ul>
                                <h4>ETAPA<span class="number">3</span></h4>
                                <ul class="etapa-list">
                                    <li>TORRE TRES</li>
                                    <li><b>PISCINA</b> ADULTOS</li>
                                    <li>ÁREA<b>SOLÁRIUM</b></li>
                                    <li><b>JACUZZI</b> E <b>HIDROMASAJES</b></li>
                                    <li>ZONA <b>MULTICHORROS</b></li>
                                    <li>BAÑOS Y VESTIERES</li>
                                    <li><b>SPORT BAR</b></li>
                                </ul>
                              
                            </Col>
                            <Col md={6} className="pt60">                          
                                <h4>ETAPA<span class="number">4</span></h4>
                                <ul class="etapa-list">
                                    <li>TORRE CUATRO</li>
                                    <li><b>PARQUE CANINO</b></li>
                                    <li><b>GIMNASIO</b> AL AIRE LIBRE</li>
                                    <li>ZONA <b>AERÓBICOS, TRX, CROSSFIT</b></li>
                                </ul>
                                <h4>ETAPA<span class="number">6</span></h4>
                                <ul class="etapa-list">
                                    <li>CENTRO EMPRESARIAL</li>
                                    <li><b>COPETRAN</b></li>
                                </ul>
                                <h4>ETAPA<span class="number">7</span></h4>
                                <ul class="etapa-list">
                                    <li>CENTRO <b>COMERCIAL</b></li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={7} sm={12} className="">
                        
                        <Row>
                             <img src={plano} className="img-elegancia" alt="plano" />
                        </Row>
                        <Row>
                            <Col className="bottom-yellow-line">
                            
                            </Col>
                        </Row>

                    </Col>
                    </Row>
                    <Row className="paddingt40">
                            <Col md="1" className="disclaimer">
                                <img src={asterisco} width="40"  alt="asterisco"/>
                            </Col>
                    </Row>
                        <Row className="paddingt40">
                            <Col md="12" className="disclaimer">
                            * Las imágenes son ilustrativas y están sujetas a modificaciones sin previo aviso. El mobiliario y la decoración no forman parte del proyecto a entregar por parte de la constructora.
                            <br/><br/>
                            </Col>
                        </Row>
                </Container>
            </Container>        
      </Container>
   );
}

export default Proyecto;
